.vocals-view {
    margin-left: 1em;
    text-align: left;
    height: 100%;

    .split-container, .tab-container, .canvas-container {
        height: 100%;
    }

    .vocal-range {
        header {
            font-size: 1.2em;
            font-weight: bold;
        }
        button.toggle {
            border: none;
            font-size: .8em;
            box-shadow: none;
            background-color: transparent;
        }
        .note {
            border: 1px dashed lightgray;
            padding: 0 4px;
        }
        .test-range {
            margin: .5em 2em;
            > label {
                display: inline-block;
                text-align: right;
                margin-right: .5em;
            }
            span {
                display: inline-block;
                margin-right: .5em;
            }
            .range-note {
                width: 3em;
                text-align: center;
                &.true {
                    background-color: green;
                }
            }
            .current-note {
                width: 3em;
                background-color: red;
                text-align: center;
            }
            button {
                margin-left: .5em;
            }
            button.cancel {
                border: none;
                box-shadow: none;
                color: gray;
                background-color: transparent;
                font-size: .9em;
            }
        }
    }

    .audio-visualizer, .tune-capture, .scales {
        height: 100%;

        .canvas-container {
            // Needs to be relative for the popup spans below
            position: relative;
            overflow: hidden;
            transition: none;
            canvas {
                border: 1px solid gray;
                border-radius: .25em;
                box-shadow: 0 0 var(--glow-size-component) var(--glow-color);
                background-color: black;
            }
            span {
                position: absolute;
                display: block;
                height: 1.5em;
                padding: 0 .25em;
                background-color: rgba(255, 255, 255, .8);
                color: black;
                cursor: default;
            }
        }
    }

    .actions {
        margin-bottom: .5em;
        label {
            margin-left: .5em;
        }
        input {
            width: 2em;
        }
        .reset-range {
            margin-left: .5em;
        }
    }

    .tab-container {
        .tab-list {
            border-bottom: 1px solid gray;
            margin-bottom: .5em;
            >span {
                display: inline-block;
                padding: .2em .5em;
                cursor: pointer;
                color: gray;
                &.active {
                    border-bottom: 3px solid var(--glow-color);
                    color: inherit;
                }
                &:hover {
                    color: inherit;
                }
            }
        }
    }
}

.App.light .canvas-container>canvas {
    background-color: white;
}