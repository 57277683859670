.react-toggle.toggle-switch {
    vertical-align: middle;
    margin-left: .25em;
    .react-toggle-track-check {
        width: 14px;
        height: 14px;
    }
    // .react-toggle-track-x {
    //     width: 14px;
    //     height: 14px;
    //     right: 8px;
    // }
    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #4D4D4D;
    }
    &.react-toggle--checked {
        &:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #19AB27;
        }
    }
}
