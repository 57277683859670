.keyboard-view {
    .keyboard {
        display: inline-block;
        padding: .5em 1em;
        position: relative;
        background-image: linear-gradient(#000, #333);
        box-shadow: 0 0 var(--glow-size-component) var(--glow-color);
        border: .2em solid black;
        border-radius: 0 0 .5em .5em;

        * {
            vertical-align: top;
        }
        
        .backboard {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1em;
            background-color: #222;
            box-shadow: 0 3px 8px #111;
        }
        canvas {
            touch-action: none;
            cursor: pointer;
        }
    }
    .control-container {
        display: inline-block;
        margin: .25em auto;
        width: 1000px;
        text-align: left;
    }
    .share {
        position: relative;
        select {
            width: 4em;
        }
    }
    .share-link {
        background-color: whitesmoke;
        text-align: left;
        margin: .5em;
        input {
            font-size: 1em;
            width: 20em;
            padding: .25em;
            margin-right: .5em;
            border: 1px solid gray;
            box-shadow: none;
        }
        button {
            color: black;
            border: 1px solid gray;
            background-color: transparent;
            box-shadow: none;
            width: 4em;
        }
    }
}

@media only screen and (max-height: 680px) {
    .keyboard-view {
        margin-top: -8em;
    }
}
@media only screen and (max-height: 550px) {
    .keyboard-view {
        margin-top: -11em;
    }
}
@media only screen and (max-width: 1200px) {
    .keyboard {
        padding: .25em;
    }
}