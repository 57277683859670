.popup-menu-button {
    position: relative;
    display: inline-block;
    & > button, & > select {
        min-width: 2em;
        height: 1.5em;
        margin: 0 .1em;
        background-color: rgba(0,0,0,.5);
        transition: all 150ms;
    }
    & > select {
        font-size: 1em;
        color: gainsboro;
        border: 1.5px solid var(--glow-color);
        border-radius: .25em;
        box-shadow: 0 0 var(--glow-size) var(--glow-color);
    }

    .popup {
        z-index: 1;
        position: absolute;
        white-space: nowrap;
        left: 3em;
        top: 0;
        padding: 0 .25em;
        background-color: black;
        border: 1.5px solid var(--glow-color);
        box-shadow: 0 0 12px var(--glow-color);
        & > div {
            display: flex;
            button, span {
                flex: 1 1 0px;
                text-align: left;
                margin: 0 1em;
            }
        }
        button, span {
            margin: 0;
            width: auto;
            min-width: 1.5em;
            border: none;
            box-shadow: none;
            background-color: transparent;
            vertical-align: baseline;
            padding: 3px 6px;
        }
        button:hover {
            box-shadow: 0 0 10px var(--glow-color);
            background-color: var(--hover-color-strong);
        }
        .newline {
            display: block;
            padding: 0;
        }
    }
}