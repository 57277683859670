.settingsView {
    margin: 0 2em;
    text-align: left;
}

.settingsView section {
    font-size: 125%;
    text-align: left;
    margin: 1em;
}

.settingsView label {
    display: inline-block;
    margin-right: .5em;
    min-width: 5.5em;
    text-align: right;
}

.settingsView section >div {
    margin-top: .5em;
}
  