.help-view {
        header {
        margin: .5em;
        text-align: center;
    }

    svg.powerchord {
        width: 500px;
        fill: whitesmoke;
    }

    svg.logo {
        width: 80px;
        background-color: whitesmoke;
        fill: black;
    }

    svg.new-window {
        display: inline-block;
        width: .8em;
        height: .8em;
        fill: var(--glow-color);
        vertical-align: middle;
    }

    section {
        font-size: 1em;
        text-align: center;
    }

    a, a:visited {
        color: var(--glow-color);
    }

    .help {
        font-size: 1.2em;
    }
}