.share-link {
    background-color: whitesmoke;
    text-align: left;
    margin: .5em;
    input {
        font-size: 1em;
        width: 20em;
        padding: .25em;
        margin-right: .5em;
        border: 1px solid gray;
        box-shadow: none;
    }
    button {
        color: black;
        border: 1px solid gray;
        background-color: transparent;
        box-shadow: none;
        width: 4em;
    }
}
