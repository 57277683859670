.progression-view {
    margin: 0 1em;
    text-align: left;

    .builder {
        color: gray;
    }

    .chord-list {
        display: block;
        margin: .5em;
        font-size: 125%;

        >ol {
            margin: .5em 0;
            padding: 0;
        }

        li {
            position: relative;
            display: inline-block;
            min-width: 1em;
            background-color: rgba(255,255,255,.5);
            color: black;
            vertical-align: baseline;
            text-align: center;
            border-left: 2px solid black;
            font-size: 1.1em;
            font-weight: bold;
            >span {
                display: inline-block;
                padding: .25em .5em;
                >sup {
                    font-size: .5em;
                }
            }
            >div.beats{
                display: flex;
                border-top: 1px solid black;
                >span {
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid #222222;
                    border-right: none;
                    flex-grow: 1;
                    font-size: .75em;
                    min-width: 1.2em;
                    color: transparent;
                    background-color: rgba(255,255,255,.6);
                    &:nth-child(odd) {
                        background-color: rgba(255,255,255,.5);
                    }
                    &:last-child {
                        color: #666666;
                    }
                    &:hover {
                        color: #222222;
                    }
                }
            }
            >button.delete {
                position: absolute;
                right: 0;
                top: 0;
                border: none;
                font-size: .5em;
                font-weight: bold;
                color: #555555;
                box-shadow: none;
                // background-color: rgba(255,255,255,.5);
                background-color: transparent;
                padding: 0 2px;
            }
            &:hover>button {
                color: #222222;
            }
            &:first-of-type {
                border-radius: 10% 0 0 10%;
            }
            &:last-of-type {
                border-radius: 0 10% 10% 0;
            }

            &.selected {
                background-color: rgba(255,255,255,.8);
            }
            &.summary {
                background-color: transparent;
                button {
                    display: block;
                    color: gray;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    background-color: transparent;
                    transition: color .25s;
                    &:hover {
                        color: silver;
                    }
                }
                span {
                    color: silver;
                    font-size: .6em;
                }
            }
        }
    }

    span.message {
        border: none;
        color: gray;
        box-shadow: none;
        background-color: transparent;
    }

    .chord-selector {
        display: inline-block;
        margin: .75em;
        text-align: left;
        label {
            vertical-align: top;
        }

        .chords-in-key label, .other-chords label {
            display: inline-block;
            width: 3em;
            vertical-align: baseline;
            text-align: right;
            margin-right: .25em;
        }
        .chords-in-key {
            button {
                min-width: 2.25em;
                margin: .2em;
                font-size: 110%;
            }
            button.not-suggested {
                opacity: .5;
            }
            button .chord-number {
                display: block;
                color: rgb(255, 191, 73);
                font-size: .8em;
                border-top: 1px solid gray;
            }
            label.beats {
                white-space: nowrap;
                margin-left: 1em;
                input {
                    display: inline-block;
                    width: 2em;
                }
            }
        }
        .other-chords {
            margin-top: .25em;
            button {
                height: 1.75em;
            }
            >button {
                margin-left: .5em;
            }
        }
    }

    progress {
        width: 6em;
    }

    .controls {
        display: block;

        section {
            font-size: 1em;
            margin: .5em;
            label {
                display: inline-block;
                margin-right: .5em;
            }
            input {
                vertical-align: text-bottom;
            }
            input[type=number] {
                width: 3em;
                margin-left: .5em;
            }
            button {
                margin: 0 1em 0 0;
            }
            .key-selector {
                display: inline-block;
            }
        }

        .toolbar {
            background-color: transparent;
            display: block;
            padding: .25em;
            margin: 0;
            font-size: 1em;

            button {
                margin: 0;
                display: inline-block;
                width: 2em;
                height: 2em;
                border: none;
                box-shadow: none;
                background-color: transparent;
                &:hover {
                    box-shadow: 0 0 var(--glow-size) var(--glow-color);
                    background-color: var(--hover-color);
                }

                &.redo {
                    transform: scaleX(-1);
                }
                &.disabled {
                    opacity: .5;
                }
            }
        }
    }
}