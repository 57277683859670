.App {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    color: white;
    --bg-intensity: 40;
    --bg-intensity-low: 0;
    --glow-size: 0;
    --glow-size-hover: 0;
    --glow-size-component: 0;
    --hover-alpha: .1;
    --hover-alpha-strong: .3;

    
    >.split-container {
        height: 100%;
    }

    &.led-on {
        --glow-size: 3px;
        --glow-size-hover: 6px;    
        --glow-size-component: .5em;
    }

    &.light {
        color: black;
        --bg-intensity: 240;
        --bg-intensity-low: 210;

        .views label {
            color: darkgreen;
        }

        .toolbar button svg {
            fill: black;
            color: black;
        }
    }

    &.Red {
        --glow-color: rgb(255, 0, 0);
        --hover-color: rgba(255, 0, 0, var(--hover-alpha));
        --hover-color-strong: rgba(255, 0, 0, var(--hover-alpha-strong));
    }

    &.Yellow {
        --glow-color: rgb(255, 255, 0);
        --hover-color: rgba(255, 255, 0, var(--hover-alpha));
        --hover-color-strong: rgba(255, 255, 0, var(--hover-alpha-strong));
    }

    &.Green {
        --glow-color: rgb(0, 255, 0);
        --hover-color: rgba(0, 255, 0, var(--hover-alpha));
        --hover-color-strong: rgba(0, 255, 0, var(--hover-alpha-strong));
    }

    &, &.Cyan {
        --glow-color: rgb(0, 255, 255);
        --hover-color: rgba(0, 255, 255, var(--hover-alpha));
        --hover-color-strong: rgba(0, 255, 255, var(--hover-alpha-strong));
    }

    &.Blue {
        --glow-color: rgb(0, 0, 255);
        --hover-color: rgba(0, 0, 255, var(--hover-alpha));
        --hover-color-strong: rgba(0, 0, 255, var(--hover-alpha-strong));
    }

    &.Magenta {
        --glow-color: rgb(255, 0, 255);
        --hover-color: rgba(255, 0, 255, var(--hover-alpha));
        --hover-color-strong: rgba(255, 0, 255, var(--hover-alpha-strong));
    }

    &.White {
        --glow-color: rgb(255, 255, 255);
        --hover-color: rgba(255, 255, 255, var(--hover-alpha));
        --hover-color-strong: rgba(255, 255, 255, var(--hover-alpha-strong));
    }

    &.Black {
        --glow-color: rgb(0, 0, 0);
        --hover-color: rgba(0, 0, 0, var(--hover-alpha));
        --hover-color-strong: rgba(0, 0, 0, var(--hover-alpha-strong));
    }

    &.None {
        --glow-color: initial;
        --hover-color: transparent;
        --hover-color-strong: transparent;
    }

    &.bgRed {
        background: rgb(var(--bg-intensity), var(--bg-intensity-low), var(--bg-intensity-low));
    }

    &.bgYellow {
        background: rgb(var(--bg-intensity), var(--bg-intensity), var(--bg-intensity-low));
    }

    &.bgGreen {
        background: rgb(var(--bg-intensity-low), var(--bg-intensity), var(--bg-intensity-low));
    }

    &.bgCyan {
        background: rgb(var(--bg-intensity-low), var(--bg-intensity), var(--bg-intensity));
    }

    &, &.bgBlue {
        background: rgb(var(--bg-intensity-low), var(--bg-intensity-low), var(--bg-intensity));
    }

    &.bgMagenta {
        background: rgb(var(--bg-intensity), var(--bg-intensity-low), var(--bg-intensity));
    }

    &.bgGray {
        background: rgb(var(--bg-intensity), var(--bg-intensity), var(--bg-intensity));
    }

    &.bgWhite {
        background: rgb(255, 255, 255);
    }

    &.bgBlack {
        background: rgb(0, 0, 0);
    }
}

.privacy-policy {
    font-size: 110%;
    color: whitesmoke;
    background-color: #222;
    padding: .5em 2em;
    text-align: left;
    .left {
        display: flex;
        align-items: center;
    }
    button {
        font-size: 100%;
        border: 2px solid yellow;
        color: yellow;
        background-color: transparent;
        padding: .5em;
        font-weight: bold;
        cursor: pointer;
    }
}

.error {
    color: red;
}

.views {
    height: 100%;
    overflow: auto;
    transition: all 150ms;

    button {
        cursor: pointer;
        border: 1px solid var(--glow-color);
        border-radius: .25em;
        box-shadow: 0 0 var(--glow-size) var(--glow-color);
        background-color: rgba(0, 0, 0, .5);
        color: gainsboro;
        font-size: 100%;
        vertical-align: middle;

        &:hover {
            // background-color: var(--hover-color);
            box-shadow: 0 0 var(--glow-size-hover) var(--glow-color);
        }

        &:focus {
            outline: 0;
        }

        &.unbuttoned {
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }

    label {
        color: limegreen;
        margin-right: .1em;
    }

    input {
        border: 1px solid var(--glow-color);
        box-shadow: 0 0 var(--glow-size) var(--glow-color);
    }

    .toolbar button svg {
        fill: white;
        color: white;
    }
}

.triangle-icon,
.square-icon {
    fill: whitesmoke;
    height: .7em;
    width: .7em;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideDownAnimation {
    0% {
        top: -3em;
    }

    100% {
        top: 0;
    }
}