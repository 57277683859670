nav {
    position: relative;
    width: 3em;
    height: 100%;
    padding-top: 1em;
    background: black;
    color: whitesmoke;
    transition: all 150ms;

    &.expanded {
        width: 9em;

        a label {
            display: inline-block;
        }

        button {
            transform: rotate(180deg);
        }
    }

    a {
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        background: transparent;
        color: whitesmoke;
        height: 2.5em;
        padding: 0;
        margin: .1em;
        border: none;
        border-color: transparent;
        text-decoration: none;
        text-align: left;
        overflow: hidden;
        transition: all 150ms;

        &:hover {
            border-left: .15em solid transparent;
        }

        label,
        svg {
            margin: 0 .25em;
            display: inline-block;
            cursor: pointer;
        }

        svg {
            width: 1.1em;
            text-align: center;
            font-size: 1.5em;
            fill: #ccc;
        }

        &.active {
            border-left: .15em solid var(--glow-color);
        }
        
        &:hover svg,
        &.active svg,
        &.active label {
            color: whitesmoke;
            fill: whitesmoke;
        }

        label {
            display: none;
            font-size: .9em;
            color: navajowhite;
        }
    }

    button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        bottom: 1.5em;
        right: -.4em;
        width: 2em;
        height: 2em;
        border: none;
        border-radius: 50%;
        background-color: #ccc;
        color: var(--glow-color);
        transition: transform 150ms;

        svg {
            width: 1.2em;
            fill: black;
        }
    
        &:hover {
            box-shadow: 0 0 var(--glow-size-hover) var(--glow-color, white);
            background-color: #ddd;
            cursor: pointer;
        }
    }
}

// @media only screen and (max-width: 1200px) {
//     nav button {
//         left: .4em;
//         right: unset;
//     }
// }