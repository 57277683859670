.controls {
    .split-container {
        flex-wrap: nowrap;
    }

    section {
        text-align: left;

        &.section-1 {
            text-align: right;
            margin-right: 2em;
        }
        > div {
            margin-top: .5em;
        }
        div.large {
            margin-top: .2em;
            font-size: 1.2em;
            span {
                font-weight: bold;
                color: rgb(255, 191, 73);
            }
        }
        > div.sound {
            margin-top: 3em;
        }
        input[type=checkbox] {
            font-size: 100%;;
            width: 1em;
            height: 1em;
            vertical-align: middle;
        }
    }

    .sustain {
        margin-left: 1em;
        >span {
            margin-left: .2em;
        }
    }

    .share {
        position: relative;
        select {
            width: 4em;
        }
    }
}

.chord-selection > div {
    display: block;
}
