.rhythm-view {
    margin-left: 1em;

    .react-toggle {
        svg {
            transform: scaleX(-1);
        }
    }

    h2 {
        text-align: left;
    }

    .grid {
        margin-top: 0;
    }

    table {
        border-collapse: collapse;
        background-color: gray;
        border: 0.1em solid gray;
        box-shadow: 0 0 var(--glow-size-component) var(--glow-color);

        th, td {
            min-width: 1.5em;
            height: 1.5em;
            text-align: center;
            background-color: black;
            border: 1px solid gray;
            padding: .2em;
            font-weight: normal;
        }
        td {
            border-radius: .5em;
            cursor: pointer;
            span {
                display: flex;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        th {
            background-color: rgba(0, 0, 0, .6);
            font-size: .9em;
        }
        tbody th {
            text-align: left;
        }
        tbody tr {
            transition: all 250ms;
        }
        // tbody tr:hover {
        //     background-color: rgba(255, 255, 255, .1);
        // }
        td.hilite {
            border-color: var(--glow-color);
            // background-color: rgba(0, 0, 0, 0.6);
        }
        // .bar1 {
        //     background-color: rgba(0, 0, 0, 0.2);
        // }
        // th.hilite {
        //     background-color: var(--glow-color);
        //     color: black;
        // }

        .red {
            background-color: red;
        }
        .yellow {
            background-color: yellow;
        }
        .green {
            background-color: green;
        }
        .cyan {
            background-color: cyan;
        }
        .blue {
            background-color: blue;
        }
        .magenta {
            background-color: magenta;
        }
        .orange {
            background-color: orange;
        }
        .limegreen {
            background-color: limegreen;
        }
        .teal {
            background-color: teal;
        }
        .royalblue {
            background-color: royalblue;
        }
        .purple {
            background-color: purple;
        }
        .olive {
            background-color: olive;
        }

        .popup-menu-button > button {
            border: none;
            color: inherit;
        }

        .delete-button {
            margin: .2em;
            color: black;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .controls {
        display: block;

        section {
            font-size: 1em;
            margin: .5em;
            label {
                display: inline-block;
                margin-right: .75em;
            }
            input {
                vertical-align: text-bottom;
            }
            input[type=number] {
                width: 3em;
            }
            input, select {
                margin-left: .5em;
            }
            button {
                margin: 0 1em 0 0;
                width: 4em;
                svg {
                    fill: white;
                    color: white;
                }
            }
        }

        .toolbar {
            background-color: transparent;
            display: block;
            padding: .25em;
            margin: 0;
            font-size: 1em;

            button {
                margin: 0;
                display: inline-block;
                width: 2em;
                height: 2em;
                border: none;
                box-shadow: none;
                background-color: transparent;
                &:hover {
                    box-shadow: 0 0 var(--glow-size) var(--glow-color);
                    background-color: var(--hover-color);
                }

                &.redo {
                    transform: scaleX(-1);
                }
                &.disabled {
                    opacity: .5;
                }
            }
        }
    }
}