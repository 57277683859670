.file-picker {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .5);
    z-index: 1;
    animation: fadeInAnimation 200ms, slideDownAnimation 200ms;

    .content {
        background-color: silver;
        width: 30em;
        padding: 0 .5em .5em .5em;
        margin: auto;
        border-radius: .25em;
        border: 1px solid gray;
        box-shadow: 0 0 1em dimgray;

        header {
            color: #444;
            padding-bottom: .25em;
            font-weight: bold;
        }

        ul {
            margin: 0;
            padding: 0 .5em;
            height: 6em;
            background-color: ghostwhite;
            list-style: none;
            color: black;
            overflow: auto;

            li {
                text-align: left;
                cursor: pointer;
                &:hover {
                    background-color: var(--hover-color-strong);
                }
            }
        }
        label {
            display: block;
            text-align: left;
            color: #333;
            font-weight: normal;
            font-size: 1em;
            margin-top: .25em;
        }
        .description {
            color: #333;
            font-style: italic;
            font-weight: normal;
        }
        input {
            width: 99%;
            font-size: 1em;
            border: 1px solid dimgray;
            padding: .2em;
        }
        .buttons {
            margin-top: .5em;
        }
        button {
            padding: .25em .5em;
            opacity: .8;
            background-color: dimgray;
            border: none;
            margin: 0 .5em;
        }
        button.ok {
            background-color: green;
        }
        button:hover {
            opacity: 1;
        }
    }
}