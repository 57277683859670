.guitar-view {
    margin-top: 1em;

    canvas {
        border: .2em solid black;
        box-shadow: 0 0 var(--glow-size-component) var(--glow-color);
        cursor: pointer;
    }
    
    .chord-view {
        section {
            text-align: right;
        }
        button {
            font-size: .7em;
            vertical-align: top;
            border-radius: 0 0 .5em .25em;
            box-shadow: none;
        }
        button.selected {
            color: gray;
            background-color: rgba(255, 255, 255, 0.9);
            font-weight: bold;
        }
        
        canvas {
            box-shadow: none;
            vertical-align: bottom;
        }
    }

    .chord-selection {
        margin-bottom: .25em;
        /* font-size: 125%; */
    }
    
    .control-container {
        display: inline-block;
        margin: .25em auto;
        width: 1000px;
        text-align: left;

        .string-fret-note {
            span {
                display: inline-block;
                width: 3em;
            }
        }
    }

    .share {
        position: relative;
        select {
            width: 4em;
        }
    }
}
