$large-width: 113em;    // 1808px
$medium-width: 68em;    // 1088px
$small-width: 45em;     // 720px
$color: #333;

.landing {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: whitesmoke;
    color: $color;
    overflow: auto;
    font-family: Lato,Helvetica Neue,Helvetica,sans-serif;
    transition: all 200ms;
    scroll-behavior: smooth;

    button, .button {
        display: inline-block;
        border: none;
        border-radius: .25em;
        box-shadow: none;
        background-color: transparent;
        font-size: 1em;
        cursor: pointer;
        transition: all 200ms;
    }
    svg.arrows {
        width: 1em;
        fill: #ddd;
        vertical-align: text-top;
    }

    .top-nav {
        position: fixed;
        background-color: #2c2c2c;
        color: silver;
        top: 0;
        left: 0;
        right: 0;
        height: 3em;
        text-align: center;
        box-shadow: 0 0 2px black;

        button.start {
            margin: .25em;
        }
    
        .logo {
            float: left;
            width: 2.5em;
            fill: #ddd;
            margin: .25em;
        }
    }
    .splash {
        position: relative;
        display: flex;
        width: 100%;
        height: 99%;
        justify-content: center;
        background-image: url("./promo.jpg");
        background-position: center;
        background-size: cover;

        .content {
            margin: auto;
            width: 50%;
            .powerchord {
                margin-top: -6em;
                fill: #111144;
                fill: whitesmoke;
            }
            .tagline {
                font-size: 2em;
                text-align: right;
                margin-top: -.75em;
                color: whitesmoke;

                @media only screen and (max-width: $large-width) {
                    font-size: 1.5em;
                }
                @media only screen and (max-width: $medium-width) {
                    font-size: 1em;
                }
                @media only screen and (max-width: $small-width) {
                    font-size: .75em;
                }
            }
        }
        .footer {
            font-size: 1.5em;
            position: absolute;
            bottom: 1em;
            svg {
                width: 1em;
                fill: whitesmoke;
                transform: rotate(90deg);
            }
            .button {
                display: flex;
                justify-content: center;
                width: 1.5em;
                height: 1.5em;
                padding: .25em;
                border-radius: 50%;
                &:hover {
                    background-color: rgba(255, 255, 255, .2);
                }
            }
        }
    }

    .row {
        display: flex;
        text-align: center;
        margin: 2em auto;
        width: 60em;
    
        h1 {
            display: inline-block;
            padding: .5em 1em;
            color: $color;
            border-bottom: 1px solid silver;
        }
        p {
            font-size: 1em;
            line-height: 1.5;
            max-width: 30em;
            margin: auto;
            text-align: left;
        }
        .column {
            img {
                width: 360px;
            }
        }
    }

    button.start {
        font-size: 1.25em;
        margin: auto;
        padding: .35em .5em;
        background-color: #070;
        color: #ddd;
        text-transform: uppercase;
        &:hover {
            background-color: #080;
            color: whitesmoke;
        }
    }

    .video {
        text-align: center;
        padding: 2em 0 4em 0;
        margin-top: 3em;
        background-color: white;
    }

    .call-to-action {
        text-align: center;
        background-color: aquamarine;
        padding: 1em;
        >* {
            margin: 1em auto;
            max-width: 40em;
        }
        .logo {
            width: 8em;
            margin-top: 1em;
        }
    }

    .bottom-footer {
        text-align: center;
        margin-top: 0em;
        padding: .5em;
        font-size: 1em;
        background-color: #424242;
        color: silver;
        p {
            margin: 1em;
        }
        a {
            text-decoration: none;
            color: silver;
        }
        .logo {
            float: left;
            width: 5em;
            fill: silver;
        }
    }
}