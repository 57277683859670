.theory-view {
    /* display: inline-block; */
    margin: 0 2em;
    text-align: left;

    >div {
        display: inline-block;
        vertical-align: top;
    }

    section {
        /* display: flex; */
        font-size: 125%;
        margin: .75em 2em;
        text-align: left;
    }

    section.selectKey {
        justify-content: center;
        margin-bottom: 1em;
    }

    .notesInKey {
        display: table;
        >div {
            padding: 0 .5em;
            border: 1px solid var(--glow-color);
            box-shadow: 0 0 var(--glow-size-component) var(--glow-color);
            border-radius: .25em;
            padding: .25em 1em .5em 1em;
            margin-bottom: 1em;
        }
        ol {
            padding: 0;
            margin: .5em 0;
            border-radius: .25em;
        }
        li {
            display: inline-block;
            cursor: default;
            text-align: center;
            width: 2.3em;
            padding: 0 .5em;
            font-weight: bold;
        }
        li span {
            display: block;
            color: silver;
            font-size: .9em;
            font-weight: normal;
            border-top: 1px solid gray;
        }
        .note-names, .triad-names {
            li {
                background-color: rgba(255,255,255,.2);
                color: whitesmoke;
                margin: 0 1px;
                cursor: pointer;
            }
            li:hover {
                background-color: rgba(255,255,255,.25);
            }
            li.tonic {
                color: #D90;
            }
            li.major {
                color: #090;
            }
            li.minor {
                color: #0BB;
            }
            li.dim {
                color: #099;
            }
            li.selected {
                background-color: rgba(255,255,255,.3);
            }
            li:first-of-type {
                border-radius: .25em 0 0 .25em;
            }
            li:last-of-type {
                border-radius: 0 10% 10% 0;
            }
        }

        .signatureCanvas {
            margin-left: 1em;
            border: none;
            box-shadow: none;
            text-align: center;
            canvas {
                display: block;
                margin: 0 auto
            }
        }
    }

    .circleOfFifths {
        margin: 1em;
        canvas {
            border: 1px solid var(--glow-color);
            border-radius: 50%;
            box-shadow: 0 0 var(--glow-size-component) var(--glow-color);
        }
        @media only screen and (max-height: 750px) {
            canvas {
                width: 75%;
                height: 75%;
            }
        }
        @media only screen and (max-height: 600px) {
            canvas {
                width: 50%;
                height: 50%;
            }
        }
    }
}