.modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        position: relative;
        display: inline-block;
        padding: 1em;
        background-color: whitesmoke;
        border: 1px solid black;
        border-radius: .25em;
        box-shadow: 0 0 8px black;

        button.close {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;
            color: red;
            font-size: 1.2em;
            font-weight: bold;

            &:hover {
                background-color: silver;
            }
        }
    }
}